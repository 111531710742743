@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.cdnfonts.com/css/agane");

body {
  font-family: "Aganè", sans-serif !important;

  /* font-family: "Titan One", cursive !important; */
}

a {
  text-decoration: none !important;
}

.email-tag {
  text-decoration: underline !important;
  color: white;
}

.banner-links {
  font-weight: 900;
  font-size: 1rem;
  color: white;
  margin: 10px 45px;
  cursor: pointer;
}

.benefits-text {
  text-align: left;
  margin: 0 10%;
  color: #ffc108;
  font-size: 10vh;
  font-weight: bolder;
  letter-spacing: -5px;
}

.about-header {
  /* font-family: "Agane", Extra Bold; */
  font-weight: bolder;
  font-size: 4.5rem;
  letter-spacing: -4px;
  color: white;
  margin-left: 15px;
  letter-spacing: -9px;
}

.about-text {
  margin-bottom: 0;
  margin-top: -2vh;
  margin: 0 15%;
  text-align: left;
  font-size: 19px;
}

.home {
  width: 100%;
  height: 100vh;
  background-image: url("/src/images/home_page.jpg");
  background-position: center top;
  background-size: cover;
}

.page-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.first-letter {
  color: #ffc107;
  font-family: Georgia;
  font-size: 60px;
  line-height: 60px;
}

.first-letter-covid {
  color: red;
  font-family: Georgia;
  font-size: 60px;
  line-height: 60px;
}

/* .first-letter {
  font-size: 35px;
  line-height: 70px;
} */
.navbar-light .navbar-nav .nav-link {
  color: white !important;
}

.headingMargin {
  color: white !important;
  margin-right: 2vh;
}

.headingButton {
  margin-right: 7vh !important;
  border-radius: 20px !important;
  width: 25vh !important;
  height: 6vh !important;
  font-size: 2.5vh !important;
  font-weight: 900 !important;
}

.perfectHeading {
  margin: 25% 0;
  color: white;
  font-size: 10vh;
  margin-bottom: 0;
}

.perfectHeading-covid {
  content: "";
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 10vh;
  letter-spacing: -5px;
  margin-left: 10%;
}

.smileHeading {
  color: white;
  font-size: 4vh;
}

@media only screen and (max-width: 450px) {
  .perfectHeading {
    /* margin: 60% 23%; */
    margin: 30vh 10vh;
    color: white;
    font-size: 5vh;
    margin-bottom: 0;
    letter-spacing: -5px;
  }

  .about-header {
    font-size: 6vh;
    letter-spacing: -4px;
  }
}
@media only screen and (device-width: 768px) {
  .perfectHeading {
    margin: 40% 0;
    color: white;
    font-size: 10vh;
    margin-bottom: 0;
  }

  .smileHeading {
    color: white;
    font-size: 2vh;
  }

  .tableContent {
    display: none;
  }
  .tableContent_2 {
    display: block;
  }
}

@media (min-width: 1200px) {
  .perfectHeading {
    margin: 15% 0;
    color: white;
    font-size: 10vh;
    margin-bottom: 0;
    letter-spacing: -5px;
    font-weight: bolder;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.header {
  text-align: center;
  padding: 32px;
}

.gridRow {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
  margin: auto 12%;
  width: 80%;
  /* margin-bottom: 15vh; */
}

@media (min-width: 1024px) {
  .gridRow {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 17%;
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .gridRow {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
    width: 80%;
  }
}

/* Create four equal columns that sits next to each other */
.gridColumn {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 28%;
  padding: 0 4px;
}

.gridColumn img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .gridColumn {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 450px) {
  .hideElement {
    display: none;
  }

  .hideLastElement {
    display: none;
  }

  .about-text {
    margin-bottom: 0;
    margin-top: -2vh;
    margin: 0 15%;
    text-align: left;
    font-size: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .hideElement {
    display: none;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .gridColumn {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.tableContent {
  width: 60%;
  flex-wrap: wrap;
  padding: 0 4px;
  margin: auto 21%;
}

.tableContent_2 {
  /* width: 40%; */
  flex-wrap: wrap;
  padding: 0 4px;
  margin: auto 10%;
}

/* @media screen and (min-width: 320px) {
  .tableContent_2 {
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 10%;
  }
} */

/* @media screen and (max-width: 600px) {
  .tableContent {
    width: 80%;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 10%;
  }

  .tableContent_2 {
    display: block;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
  }
} */

/* @media screen and (max-width: 1024px) {
  .tableContent_2 {
    display: none;
  }
  .tableContent {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .tableContent {
    display: none;
  }
  .tableContent_2 {
    display: block;
  }
} */

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: black;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.homeNav {
  width: unset;
  display: flex;
  justify-content: center;
}

@media (min-width: 280px) {
  .tableContent_2 {
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 5%;
    display: block;
  }
  /* .about-header {
    font-size: 5vh;
    letter-spacing: -4px;
  } */
  .tableContent {
    display: none;
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  .tableContent_2 {
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 5%;
    display: block;
  }

  .tableContent {
    display: none;
  }
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .tableContent {
    width: 80%;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 10%;
    display: none;
  }

  .tableContent_2 {
    display: block;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
    display: block;
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .tableContent {
    width: 80%;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 10%;
    display: block;
  }

  .tableContent_2 {
    display: block;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
    display: none;
  }
}

/* @media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops 
  .tableContent {
    display: none;
  }
  .tableContent_2 {
    display: block;
  }
} */
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .tableContent {
    width: 80%;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 10%;
    display: block;
  }

  .tableContent_2 {
    display: block;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
    display: none;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .tableContent {
    width: 60%;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 20%;
    display: block;
  }

  .tableContent_2 {
    display: block;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto 15%;
    display: none;
  }
}

/*
#############################################
FOR HEADER
*/
/*Configurações Globais*/
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
/*Início das configurações da header*/
header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: transparent;
}

.brand {
  color: white;
  width: auto;
  height: 100%;
  float: left;
  margin: 0 0 0 5%;
}
/*Início das configurações da Navigation*/
.menu {
  width: 60%;
  height: 100%;
  float: right;
}

/* .menu ul {
  width: 100%;
  height: inherit;
  margin: 0 150px;
  padding: 0;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) 
  display: -ms-flexbox; /* TWEENER - IE 10 
  display: -webkit-flex; /* NEW - Chrome 
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ 
}

.menu ul a {
  width: 15%;
  color: white;
  display: inline-block;
} */

#menuToggle {
  display: none;
}

.menu-icon {
  display: none;
}
/*Início das configurações da Navigation com dispositivos < 768px*/
@media screen and (max-width: 768px) {
  .menu {
    width: 100%;
    height: auto;
  }
  .menu ul {
    display: block;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
    /*Para fazer com que o menu saia da esquerda, você deve trocar max-height por max-width*/
    /*Para fazer com que o menu saia da direita, você deve trocar max-height por max-width e setar float: right*/
  }
  .menu ul a {
    text-align: left;
    width: 100%;
    background-color: transparent;
    padding: 10px 0px 10px 5%;
  }
  .menu-icon {
    width: 100px;
    height: inherit;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 60px;
  }
  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }
  .menu-icon i {
    font-size: 1.7em;
    color: white;
  }
  .homeNav {
    width: unset;
    display: content;
    justify-content: center;
  }
}

/*
#############################################
FOR HEADER
*/
